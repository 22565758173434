export const locale = {
    'nl': {
        'thought_leaders': 'Thought leaders zijn individuen, bedrijven of organisaties die een leidende, onderscheidende en vooruitstrevende positie innemen binnen hun vakgebied.',
        'read_more': 'Lees meer'
    },
    'de': {
        'thought_leaders': 'Vordenker sind Einzelpersonen, Unternehmen oder Organisationen, die in ihrem Bereich eine führende, unverwechselbare und fortschrittliche Position einnehmen.',
        'read_more': 'Mehr lesen'
    },
    'en': {
        'thought_leaders': 'Thought leaders are individuals, companies or organizations who occupy a leading, distinctive and progressive position within their field.',
        'read_more': 'Read more'
    },
    'ru': {
        'thought_leaders': 'Thought leaders are individuals, companies or organizations who occupy a leading, distinctive and progressive position within their field.',
        'read_more': 'Read more'
    }
}
  