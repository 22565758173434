import React, { useContext } from "react"
import LangContext from "./../contexts/LangContext"

export const generatePath = (lang, slug) => {
  return `${lang.substring(0, 2)}/${slug ? `${slug}/` : ""}`
}

export const getBlogPostLink = slug => {
  let link =
    slug.split("/")[0] == "en"
      ? `${slug.split("/")[1]}/${slug.split("/")[2]}`
      : slug
  let url = `https://alexschoemaker.com/${link}`
  return url
}

export const getLocalLink = slug => {
  let link = slug.split("/")[1] == "en" ? slug.split("/")[2] : slug
  return `/${link}`
}

export const withLang = link => {
  const { switchLang, lang } = useContext(LangContext)
  // const { NODE_ENV } = process.env
  // const base = NODE_ENV !== 'development' ? 'https://alexschoemaker.com' : 'http://localhost:8000'
  // window.location.replace(`${base}/${ln == 'en' ? '' : ln}`)
  return `${lang}${link}`
}

export const getCurrentLang = () => {
  return (
    (typeof window !== "undefined" &&
      window.localStorage.getItem("currentLang")) ||
    "en"
  )
}
