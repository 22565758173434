import React, { useState, useLayoutEffect, useEffect } from 'react'

const LangContext = React.createContext({
  lang: '',
  currentLanguageData: {},
  switchLang: () => {},
});

export default LangContext;

export function LangProvider (props) {

    const { NODE_ENV } = process.env
    const [lang, setLang] = useState(typeof window !== 'undefined' && window.localStorage.getItem('currentLang') || 'en');
    const [languageData, setLanguageData] = useState([
        {
            lang: 'en'
        },
        {
            lang: 'de'
        },
        {
            lang: 'nl'
        },
        {
            lang: 'ru'
        }
    ])

    useEffect(() => {
        const currentLangFromUrl = window.location.pathname.split('/')[1] != '' ? window.location.pathname.split('/')[1] : 'en'
        window.localStorage.setItem('currentLang', currentLangFromUrl)
        setLang(currentLangFromUrl)
    }, [])
    
    useLayoutEffect(() => {
        const selectedLang = window.localStorage.getItem('currentLang')

        if (selectedLang) {
            setLang(selectedLang)
        } 
    }, [lang])

    const switchLang = (ln) => {
        let base = NODE_ENV !== 'development' ? 'https://alexschoemaker.com' : 'http://localhost:8000'
        setLang(ln);
        window.localStorage.setItem('currentLang', ln);
        window.location.replace(`${base}/${ln == 'en' ? '' : ln}`)
    };

    return (
        <LangContext.Provider value={{
            lang, 
            switchLang, 
            currentLanguageData: languageData[lang]
        }}>{props.children}</LangContext.Provider>
    );
};