// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-editor-js": () => import("./../../../src/pages/editor.js" /* webpackChunkName: "component---src-pages-editor-js" */),
  "component---src-templates-blogpost-entry-js": () => import("./../../../src/templates/blogpost-entry.js" /* webpackChunkName: "component---src-templates-blogpost-entry-js" */),
  "component---src-templates-not-found-template-js": () => import("./../../../src/templates/not-found-template.js" /* webpackChunkName: "component---src-templates-not-found-template-js" */),
  "component---src-templates-page-entry-js": () => import("./../../../src/templates/page-entry.js" /* webpackChunkName: "component---src-templates-page-entry-js" */)
}

