import React, { useEffect } from "react"
import { LangProvider } from "./../contexts/LangContext"

import Cursor from "./Cursor"

import { locale } from "./../../static/data"
import { getCurrentLang } from "../utils/helpers"

const Layout = ({ children, location }) => {
  useEffect(() => {
    let tlPopup = document.querySelector(".tl-popup")
    tlPopup &&
      tlPopup.setAttribute(
        "data-text",
        locale[getCurrentLang()]["thought_leaders"]
      )
  }, [])

  return (
    <LangProvider>
      {/* <Cursor /> */}
      <main className="main">{children}</main>
    </LangProvider>
  )
}

export default Layout
