import React, { useState, useEffect } from 'react'
import cx from 'classnames'

import styles from './styles.module.scss'

const isMobile = () => {
    const ua = navigator.userAgent
    return /Android|Mobi/i.test(ua)
}

const Cursor = () => {
    
    if (typeof navigator !== 'undefined' && isMobile()) return null

    const [position, setPosition] = useState({ x: -100, y: -100 })
    const [clicked, setClicked] = useState(false)
    const [hidden, setHidden] = useState(false)
    
    const [linkHovered, setLinkHovered] = useState(false)
    const [buttonHovered, setButtonHovered] = useState(false)
    const [imageHovered, setImageHovered] = useState(false)
    const [carouselClick, setCarouselClick] = useState(false)

    useEffect(() => {
        addEventListeners()
        handleHoverEvents()
        return () => removeEventListeners()
    }, [])

    const addEventListeners = () => {
        document.addEventListener('mousemove', onMouseMove)
        document.addEventListener('mouseenter', onMouseEnter)
        document.addEventListener('mouseleave', onMouseLeave)
        document.addEventListener('mousedown', onMouseDown)
        document.addEventListener('mouseup', onMouseUp)
        document.addEventListener('touchmove', onDragOver)
        document.addEventListener('pointermove', onDragOver)
    }
    
    const removeEventListeners = () => {
        document.removeEventListener('mousemove', onMouseMove)
        document.removeEventListener('mouseenter', onMouseEnter)
        document.removeEventListener('mouseleave', onMouseLeave)
        document.removeEventListener('mousedown', onMouseDown)
        document.removeEventListener('mouseup', onMouseUp)
        document.removeEventListener('pointermove', onDragOver)
    }

    const onMouseMove = (e) => {
        setPosition({ x: e.clientX, y: e.clientY })
        setClicked(false)
    }

    const onDragOver = (e) => {
        setPosition({ x: e.clientX, y: e.clientY })
        setClicked(false)
    }

    const onMouseDown = () => {
        setClicked(true)
    }

    const onMouseUp = () => {
        setClicked(false)
        setLinkHovered(false)
        setButtonHovered(false)
    }

    // Hide mouse
    const onMouseLeave = () => {
        setHidden(true)
    }

    // Show mouse
    const onMouseEnter = () => {
        setHidden(false)
    }

    const handleHoverEvents = () => {

        document.querySelectorAll('a.button, button.button').forEach((el) => {
            el.addEventListener('mouseover', () => setButtonHovered(true))
            el.addEventListener('mouseout', () => setButtonHovered(false))
        })

        document.querySelectorAll('a.link').forEach((el) => {
            el.addEventListener('mouseover', () => setLinkHovered(true))
            el.addEventListener('mouseout', () => setLinkHovered(false))
        })

        document.querySelectorAll('img, figure, .img-hover').forEach((el) => {
            el.addEventListener('mouseover', () => setImageHovered(true))
            el.addEventListener('mouseout', () => setImageHovered(false))
        })

    }

    const cursorClasses = cx({
        [styles['cursor']]: true,
        [styles['cursor--clicked']]: clicked,
        [styles['cursor--hidden']]: hidden,
        [styles['cursor--link-hovered']]: linkHovered,
        [styles['cursor--button-hovered']]: buttonHovered,
        [styles['cursor--clicked']]: carouselClick,
    })

    return (
        <div
            className={cursorClasses}
            style={{ transform: `translate(${position.x}px, ${position.y}px)` }}
        />
    )
}

export default Cursor